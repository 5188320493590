import React, { ComponentProps } from "react";
import { Image } from "react-bootstrap";

import "./Bonus.scss";
import ImageShimmer from "../../images/shimmer.svg";
import ImageGift from "../../images/gift.svg";

interface BonusProps extends ComponentProps<"div"> {
  text?: string;
}

function Bonus({
  text = "+100% Bonus",
  className = "",
  ...restProps
}: BonusProps) {
  return (
    <div
      className={`text-white bonus-display-container animated-background py-1 ${className}`}
      {...restProps}
    >
      <Image src={ImageShimmer} alt="shimmer" className="shimmer" />
      <span className="text">
        <Image className="me-1" src={ImageGift} height={12} width={12} /> {text}
      </span>
    </div>
  );
}

export default Bonus;
