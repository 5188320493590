import React from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

import { ROUTES, TRACKING_EVENTS, PAGE_NAMES } from "../../../utils/constants";
import { registerTrackingEvent, getCurrentUrl } from "../../../utils/helpers";
import "./AppDownloadButton.scss";
import useVendorDetection from "../../../hooks/useVendorDetection";
import {
  PhoneVendors,
  getVendorDownloadLink,
  getVendorLogo,
} from "../../../utils/vendor";
import Bonus from "../../Bonus/Bonus";

interface AppDownloadButtonProps {
  pageName?: string;
  nextPagePath?: string;
}

function AppDownloadButton({
  pageName = PAGE_NAMES.home,
  nextPagePath = `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.START_DOWNLOAD}`,
}: AppDownloadButtonProps) {
  const navigate = useNavigate();
  const phoneVendor = useVendorDetection();
  const isBeta = phoneVendor === PhoneVendors.APPLE;

  const handleClick = (): void => {
    registerTrackingEvent({
      name: TRACKING_EVENTS.WEB_DOWNLOAD,
      payload: { page_name: pageName, url: getCurrentUrl() },
    });
    const storeLink = getVendorDownloadLink(phoneVendor);

    if (phoneVendor === PhoneVendors.APPLE) {
      navigate(`${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.TEST_FLIGHT}`);
    } else if (phoneVendor !== PhoneVendors.SAMSUNG && storeLink) {
      window.open(storeLink, "_blank", "noopener,noreferrer");
    } else {
      navigate(nextPagePath);
    }
  };

  return (
    <span className="position-relative">
      <Bonus />
      <button
        onClick={handleClick}
        className="app-download font-reg"
        aria-label="download"
      >
        <span className="img-container">
          <Image
            src={getVendorLogo(
              phoneVendor === PhoneVendors.SAMSUNG ? null : phoneVendor
            )}
            alt="vendor"
          />
          {isBeta && <span className="beta-tag">Beta</span>}
        </span>
        <span className="download-app-text">download hyper gaming app</span>
      </button>
    </span>
  );
}

export default AppDownloadButton;
