import React, { useState, PropsWithChildren, useCallback } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Options } from "qr-code-styling";

import "./TestFlight.scss";
import ImageHyperLogo from "../../../images/hyper-full-white.svg";
import { LINKS, MVM_EMAIL } from "../../../utils/constants";
import ImageXIcon from "../../../images/x.svg";
import ImageCurve from "../../../images/vector-curve-2.svg";
import ImageIPhone from "../../../images/iphone-hunter-killer.png";
import FilledButton from "../../../components/dashboard-page/FilledButton/FilledButton";
import ImageShimmer from "../../../images/shimmer.svg";
import ImageGift from "../../../images/gift.svg";
import LastWinFlipper from "../../../components/LastWinFlipper/LastWinFlipper";
import ImageAppleLogo from "../../../images/apple-logo-white.svg";
import ImageWarpGate from "../../../images/warp-gate.png";
import ImageQrCodeIcon from "../../../images/qr-code-icon.svg";
import ImageInstagram from "../../../images/instagram-colored.svg";
import ImageTiktok from "../../../images/tiktok.svg";
import QrCode from "../../../components/QrCode/QrCode";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import ImageCheckmarkIcon from "../../../images/checkmark-brand.svg";
import ImageInstallHyper from "../../../images/install-hyper-on-testflight.png";
import Lightbox from "../../../components/Lightbox/Lightbox";

const Step = ({ children }: PropsWithChildren) => (
  <span className="step">{children}</span>
);

const MobileFooter = () => (
  <Row
    as="footer"
    className="foot d-flex flex-column d-lg-none position-relative z-2"
  >
    <Col className="logo-container">
      <Image src={ImageHyperLogo} alt="Hyper Logo" />
    </Col>
    <Col className="d-flex justify-content-center pb-3">
      <Row className="d-flex justify-content-between w-100">
        <Col className="d-flex justify-content-start align-items-end w-100 p-0 gap-3">
          <a
            href={LINKS.SOCIAL.INSTAGRAM}
            target="_blank"
            rel="noreferrer"
            aria-label="instagram"
          >
            <Image src={ImageInstagram} alt="instagram icon" />
          </a>
          <a
            href={LINKS.SOCIAL.X}
            target="_blank"
            rel="noreferrer"
            aria-label="x"
          >
            <Image src={ImageXIcon} alt="x icon" />
          </a>
          <a
            href={LINKS.SOCIAL.TIKTOK}
            target="_blank"
            rel="noreferrer"
            aria-label="tiktok"
          >
            <Image src={ImageTiktok} alt="Tik Tok icon" />
          </a>
        </Col>
        <Col className="d-flex justify-content-end align-items-end w-100 p-0 gap-2">
          <a
            href={LINKS.WEBPAGE.PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
            className="link-text"
          >
            Privacy Policy
          </a>
          <a
            href={LINKS.WEBPAGE.TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noreferrer"
            className="link-text"
          >
            Terms of service
          </a>
        </Col>
      </Row>
    </Col>
  </Row>
);

const DesktopFooter = () => (
  <footer className="desktop-footer justify-content-between d-none d-lg-flex">
    <div>
      <a href={`mailto:${MVM_EMAIL}`} className="link email">
        {MVM_EMAIL}
      </a>
      <p className="d-flex justify-content-start align-items-end w-100 p-0 gap-3 mt-2">
        <a
          href={LINKS.SOCIAL.INSTAGRAM}
          target="_blank"
          rel="noreferrer"
          aria-label="instagram"
        >
          <Image src={ImageInstagram} alt="instagram logo" />
        </a>
        <a
          href={LINKS.SOCIAL.X}
          target="_blank"
          rel="noreferrer"
          aria-label="x"
        >
          <Image src={ImageXIcon} alt="x logo" />
        </a>
        <a
          href={LINKS.SOCIAL.TIKTOK}
          target="_blank"
          rel="noreferrer"
          aria-label="tiktok"
        >
          <Image src={ImageTiktok} alt="tiktok logo" />
        </a>
      </p>
    </div>
    <Image
      src={ImageHyperLogo}
      alt="Hyper Logo"
      className="d-block"
      width={168}
    />
    <div>
      <a
        href={LINKS.WEBPAGE.PRIVACY_POLICY}
        target="_blank"
        rel="noreferrer"
        className="link d-block mb-2"
      >
        Privacy Policy
      </a>
      <a
        href={LINKS.WEBPAGE.TERMS_AND_CONDITIONS}
        target="_blank"
        rel="noreferrer"
        className="link d-block"
      >
        Terms of service
      </a>
    </div>
  </footer>
);

const QRCODE_CONFIG: Options = {
  type: "canvas",
  image: "",
  backgroundOptions: {
    color: "white",
  },
};

const videoTitle = "How To Install Hyper Gaming App on iPhone";

function TestFlight() {
  const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);
  const [hasInstalledTestflight, setHasInstalledTestflight] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const toggleInstalledStatus = useCallback(
    () => setHasInstalledTestflight((prev) => !prev),
    []
  );
  const openLightbox = useCallback(() => setIsLightboxOpen(true), []);

  return (
    <>
      <main className="TestFlight">
        <Col className="App-bar z-3 d-flex justify-content-center px-0 py-4 position-fixed">
          <Link to="/">
            <Image className="logo-img" src={ImageHyperLogo} alt="Hyper logo" />
          </Link>
        </Col>
        <Row
          as="header"
          className="Header text-white d-block d-lg-flex align-items-center"
        >
          <Col className="pb-0 isolate pt-0">
            <h1 className="z-2 font-large text-white px-3 position-relative px-xl-0">
              <span>You have a</span>
              <br /> <span></span>
              <span className="taken">
                special invite
                <Image src={ImageCurve} className="curve-img" alt="" />
              </span>
              <br />
              <span>to try Hyper.</span>
            </h1>
            <p className="z-2 join-text mt-3">
              Join before all seats are taken!
            </p>

            <a
              href="#get-app"
              className="get-app-btn z-2 position-relative d-none d-lg-flex"
            >
              <span className="d-flex flex-column align-items-center">
                <Image
                  src={ImageAppleLogo}
                  alt="apple"
                  className="apple-img d-block"
                />
                <span className="beta">
                  {" "}
                  <span>Beta</span>
                </span>
              </span>
              <span className="">Get App</span>
            </a>
          </Col>
          <Col className="z-2 d-none d-lg-block">
            <span className="d-inline-block w-auto h-auto position-relative">
              <Image src={ImageWarpGate} alt="" width={676} />
              <span
                className="d-inline-block w-auto h-auto position-absolute"
                style={{ left: 192, top: 112 }}
              >
                <QrCode
                  value={LINKS.WEBPAGE.DOWNLOAD_HYPER_QRCODE_LINK}
                  customOptions={{
                    height: 286,
                    width: 286,
                    ...QRCODE_CONFIG,
                  }}
                />
              </span>
            </span>
          </Col>
        </Row>
        <Row className="main-content d-block d-lg-flex align-items-center pb-lg-5">
          <Col className="how-to-join-section px-0">
            <h2 className="gradient-text px-4 px-lg-0">
              <span>Get Hyper App for</span> <span>iPhone</span>{" "}
            </h2>
            <p className="access-text px-2 px-lg-0">
              Follow the instructions below to use your special invite.
            </p>
            <div id="get-app" className="installation-steps-container px-1">
              <ul className="list mt-3 d-none d-lg-block">
                <li className="list-item mb-3">
                  <Step>1</Step>
                  <span className="description">
                    <a
                      href={LINKS.WEBPAGE.TEST_FLIGHT_INSTALL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click link to install TestFlight
                    </a>{" "}
                    on your iPhone from the AppStore.
                  </span>
                </li>
                <li className="list-item">
                  <Step>2</Step>
                  <span className="description">
                    <span className="qr-code-wrapper">
                      Come back to scan the QR{" "}
                      <span className="position-relative">
                        <button
                          aria-label="view qr code"
                          className="qr-button"
                          onClick={() => setIsQrCodeOpen((prev) => !prev)}
                          onMouseEnter={() => setIsQrCodeOpen(true)}
                          onMouseLeave={() => setIsQrCodeOpen(false)}
                        >
                          <Image src={ImageQrCodeIcon} alt="qr code" />
                        </button>{" "}
                        {isQrCodeOpen && (
                          <span className="qr-popup">
                            <QrCode
                              value={LINKS.WEBPAGE.TEST_FLIGHT_JOIN}
                              customOptions={{
                                width: 110,
                                height: 110,
                                ...QRCODE_CONFIG,
                              }}
                            />{" "}
                          </span>
                        )}
                      </span>
                      or{" "}
                      <a
                        href={LINKS.WEBPAGE.TEST_FLIGHT_JOIN}
                        target="_blank"
                        rel="noreferrer"
                      >
                        tap here
                      </a>{" "}
                      to get Hyper App.
                    </span>
                    <div className="d-flex justify-content-start p-0 m-0">
                      <div
                        className="bonus-container animated-background text-white position-relative"
                        style={{ left: 0, top: 0 }}
                      >
                        <Image
                          src={ImageShimmer}
                          alt="shimmer"
                          className="shimmer"
                        />
                        <span className="text">
                          <Image
                            className="me-1"
                            src={ImageGift}
                            height={12}
                            width={12}
                          />{" "}
                          +100% Bonus
                        </span>
                      </div>
                    </div>
                  </span>
                </li>
              </ul>
              <div className="d-lg-none position-relative">
                <div className="px-4">
                  <div
                    className="position-absolute"
                    style={{
                      width: 4,
                      background: "#241038",
                      top: 32,
                      bottom: hasInstalledTestflight ? 524 : 400,
                      left: 38,
                      zIndex: -2,
                    }}
                  />
                  <div
                    className="position-absolute"
                    style={{
                      width: 4,
                      background: "#6C53e8",
                      top: 32,
                      bottom: hasInstalledTestflight ? 524 : 600,
                      left: 38,
                      zIndex: -1,
                    }}
                  />
                  <div className="step mb-2">
                    <p className="step-number">
                      {hasInstalledTestflight ? (
                        <button
                          className="wrapper-btn p-0"
                          aria-label="toggle testflight installation status"
                          onClick={toggleInstalledStatus}
                        >
                          <Image alt="checkmark" src={ImageCheckmarkIcon} />
                        </button>
                      ) : (
                        <span>1</span>
                      )}
                    </p>
                    <div
                      className="step-content fw-bold"
                      style={{
                        color: hasInstalledTestflight ? "#FFFFFF99" : undefined,
                      }}
                    >
                      Install TestFlight app on your iPhone.
                    </div>
                  </div>
                  {!hasInstalledTestflight && (
                    <>
                      <div className="text-start ps-5">
                        After installing TestFlight, come back to claim your
                        special invite.
                      </div>
                      <a
                        href={LINKS.WEBPAGE.TEST_FLIGHT_INSTALL}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        <FilledButton className="ms-5 my-3">
                          {" "}
                          <Image
                            src={ImageAppleLogo}
                            alt="apple"
                            className="apple-img d-block"
                          />
                          <span>Install TestFlight</span>
                        </FilledButton>
                      </a>
                    </>
                  )}
                  <div className="d-flex align-items-center gap-2 ms-5 pb-4 px-0">
                    <CustomCheckbox
                      onChange={toggleInstalledStatus}
                      checked={hasInstalledTestflight}
                    />
                    <span
                      onClick={toggleInstalledStatus}
                      className="text-start"
                      style={{
                        color: hasInstalledTestflight ? "#FFFFFF99" : undefined,
                      }}
                    >
                      {hasInstalledTestflight
                        ? "TestFlight installed. Proceed to step 2"
                        : "I have installed TestFlight"}
                    </span>
                  </div>
                </div>
                <div className="pattern-bg">
                  {!hasInstalledTestflight && (
                    <div className="px-4">
                      <div className="step-1-instructions my-3" />
                    </div>
                  )}
                  {hasInstalledTestflight && (
                    <div className="px-4">
                      <div>
                        <div className="step">
                          <p className="step-number">2</p>
                          <div className="step-content fw-bold">
                            Install Hyper gaming app.
                          </div>
                        </div>
                        <div className="text-start ps-5">
                          After installing TestFlight, come back to claim your
                          special invite.
                        </div>
                      </div>
                      <div className="install-btn-container position-relative mt-3">
                        <FilledButton
                          className="px-4"
                          style={{ fontWeight: 500, height: 44 }}
                          onClick={() =>
                            window.open(
                              LINKS.WEBPAGE.TEST_FLIGHT_JOIN,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          Install Hyper Gaming App
                        </FilledButton>
                        <div className="bonus-container animated-background text-white">
                          <Image
                            src={ImageShimmer}
                            alt="shimmer"
                            className="shimmer"
                          />
                          <span className="text">
                            <Image
                              className="me-1"
                              src={ImageGift}
                              height={12}
                              width={12}
                            />{" "}
                            +100% Bonus
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <Col className="d-lg-none game-play-img-container px-0 mt-5 mt-lg-0">
                    <button
                      className="wrapper-btn"
                      aria-label="open explainer video"
                      onClick={openLightbox}
                    >
                      <Image
                        src={ImageInstallHyper}
                        alt="install hyper on testflight"
                        className="d-lg-none w-100"
                      />
                    </button>
                  </Col>
                </div>
              </div>
            </div>
          </Col>
          <Col className="d-none d-lg-block game-play-img-container px-3 mt-5 mt-lg-0">
            <Image
              src={ImageIPhone}
              alt="game play"
              className="d-none d-lg-inline"
            />
          </Col>
        </Row>
        <MobileFooter />
        <DesktopFooter />
        <Lightbox
          src={LINKS.VIDEO.HOW_TO_INSTALL_HYPER_ON_TESTFLIGHT}
          title={videoTitle}
          isOpen={isLightboxOpen}
          setIsOpen={setIsLightboxOpen}
        />
      </main>
      <LastWinFlipper />
    </>
  );
}

export default TestFlight;
