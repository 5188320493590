export enum WEBPAGE {
  TERMS_AND_CONDITIONS = "https://mvm.gg/hyper/terms",
  PRIVACY_POLICY = "https://mvm.gg/hyper/privacy",
  TEST_FLIGHT_JOIN = "https://testflight.apple.com/join/Q9ZhVekc",
  TEST_FLIGHT_INSTALL = "https://apps.apple.com/us/app/testflight/id899247664?mt=8",
  PALM_STORE_HYPER_PAGE = "https://m.palmplaystore.com/app/soft_detail?itemID=s_17f05e82d20e4f29a45be03bf4b5ffd2",
  AMAZON_STORE_HYPER_PAGE = "https://www.amazon.com/gp/product/B0CZV67TNV",
  GALAXY_STORE = "https://galaxy.store/dlbwfxyje",
  DOWNLOAD_HYPER_QRCODE_LINK = "https://hypergame.onelink.me/4tib/dw8iofia",
}

export enum VIDEO {
  HOW_TO_INSTALL_HYPER = "https://www.youtube.com/embed/xSZXx5jIFak?loop=1&fs=0&playsinline=0&controls=0&autoplay=1&playlist=xSZXx5jIFak",
  HOW_TO_INSTALL_HYPER_ON_TESTFLIGHT = "https://www.youtube.com/embed/Z7b_mXILKNk?loop=1&fs=0&playsinline=0&autoplay=1&playlist=Z7b_mXILKNk",
}

export enum SOCIAL {
  WHATSAPP = "https://chat.whatsapp.com/F0vmrfHNfDxGUGYh087VHM",
  X = "https://x.com/metaversemagna",
  TELEGRAM = "https://t.me/+L11t6Snlha9lYTBk",
  TIKTOK = "https://www.tiktok.com/@metaversemagna?_t=8cXDtSY4CKz&_r=1",
  INSTAGRAM = "https://www.instagram.com/metaversemagna/",
}
