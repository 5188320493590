import React, { ComponentProps } from "react";
import "./CustomCheckbox.scss";

function CustomCheckbox({
  className = "",
  ...restProps
}: Omit<ComponentProps<"input">, "type">) {
  return (
    <input
      type="checkbox"
      className={`custom-checkbox ${className}`}
      {...restProps}
    />
  );
}

export default CustomCheckbox;
